@import 'import';
.backButton {
  display: flex;
  align-items: center;

  height: 38px;
  padding: 0 10px 0 8px;

  color: $black;

  background: $white;
  border: 1px solid $silver;
  border-radius: 20px;

  @include media-breakpoint-up(lg) {
    margin-left: 15px;
  }

  .text {
    @include font($size: rem(15), $weight: 400, $line-height: 38px);

    display: block;
  }

  :global(.Icon) {
    display: flex;
    align-items: center;

    width: 28px;
    height: 38px;

    filter: invert(9%) sepia(89%) saturate(7149%) hue-rotate(12deg) brightness(90%) contrast(113%);

    fill: $vv-red;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
