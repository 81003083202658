@import 'import';
.accessKeyBanner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  max-width: 1180px;
  margin: 0 auto 10px;
  padding: 8px 12px;

  opacity: 0.8;
  background-color: $white;
  border: 1px solid $gray-110;
  border-radius: 4px;
}

.description {
  display: inline-flex;
  margin-right: 10px;

  @include font($size: rem(14), $name: 'section', $line-height: rem(23), $weight: 400, $color: $black);
}

.icon {
  margin: auto;
  padding-right: 10px;
}

.content {
  display: flex;
  justify-content: space-between;
}

.title {
  @include font($size: rem(15), $name: 'section', $line-height: rem(25), $weight: 700);
}

.dividedLine {
  border-left: 1px solid $gray-010;
}

.cta {
  display: flex;
  height: 100%;

  @include font($size: rem(13), $name: 'section', $weight: 400, $line-height: rem(16));

  :global(.Icon) {
    width: 14px;
    height: 14px;
    margin-bottom: 2px;
    fill: $gray-light;
  }
}

.label {
  font-size: rem(13) !important;
  text-decoration: underline;
}
