@import 'import';
.root {
  display: none;
  margin: 10px auto 0;
  font-size: rem(16);

  :global(.Icon) {
    rotate: -90deg;
    width: 16px;
  }

  @media (min-resolution: $zoom-more-than-250) and (min-width: $container-sm-width) {
    display: flex;
    align-items: center;
  }
}
