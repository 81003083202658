@import 'import';
.root {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  order: 2;

  margin-top: 20px;
  margin-bottom: 40px;
  padding: 0;

  @include media-breakpoint-up(md) {
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: center;

    width: 85%;
    max-width: map-get($grid-breakpoints, 'xl');
    margin: 30px auto 40px;
  }
}

.service {
  display: flex;
  flex-direction: row;
  gap: 7px 9px;

  width: 210px;

  white-space: nowrap;

  @include media-breakpoint-up(md) {
    flex-direction: column;
    align-items: center;

    width: auto;
    min-width: 128px;

    text-align: center;
    white-space: normal;
  }
}

.icon {
  width: 24px;

  .light & {
    filter: invert(9%) sepia(89%) saturate(7149%) hue-rotate(12deg) brightness(90%) contrast(113%);
  }

  .gold & {
    filter: invert(54%) sepia(55%) saturate(206%) hue-rotate(358deg) brightness(85%) contrast(80%);
  }

  @include media-breakpoint-up(md) {
    width: 40px;
  }
}

.name {
  @include font($size: rem(13), $weight: 400);

  @include media-breakpoint-up(md) {
    max-width: 115px;
  }
}
