@import 'import';
// TODO: simplify
.root {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: auto auto 30px;
  padding: 0;

  background: none;
  border: 0;
  outline: none;

  &:focus {
    outline: none;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
  }

  .slide {
    display: flex;
    align-self: center;

    width: 160px;
    height: 70px;
    margin-bottom: 10px;

    border: 1px solid #dfdfdf;
    border-radius: 4px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    .arrow {
      display: flex;
      align-items: center;
      width: 20%;

      .caret {
        width: 30px;
        margin-left: 4px;
      }
    }

    .thumbnail {
      width: 80%;
    }
  }

  .text {
    display: flex;
    flex-direction: column;

    .message {
      font-size: rem(15);
      font-weight: 500;
      color: $black;
    }

    .cabin {
      margin-bottom: 2px;
      font-size: rem(15);
      color: $black;
      text-decoration: underline;
    }

    .price {
      font-size: rem(13);
      color: $grey-text;
    }
  }

  &.right {
    .slide {
      flex-direction: row-reverse;
    }

    .slide .thumbnail {
      border-radius: 4px 0 0 4px;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row-reverse;

      .slide {
        margin-left: 20px;
      }

      .text {
        align-items: end;
      }
    }
  }

  &.left {
    .arrow {
      rotate: 180deg;
    }

    .slide .thumbnail {
      border-radius: 0 4px 4px 0;
    }

    @include media-breakpoint-up(lg) {
      .slide {
        margin-right: 20px;
      }

      .text {
        align-items: start;
      }
    }
  }

  &.dark {
    .arrow {
      background: $black;
      border-radius: 0 4px 4px 0;
    }

    .arrow .caret {
      filter: invert(54%) sepia(55%) saturate(206%) hue-rotate(358deg) brightness(85%) contrast(80%);
    }
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 90%;
  max-width: map-get($grid-breakpoints, 'xl');
  margin: rem(20) auto rem(160);

  .item {
    @include media-breakpoint-up(md) {
      align-self: center;
      min-width: rem(300);
    }
  }

  @media (min-resolution: $zoom-more-than-250) and (max-width: $container-sm-width) {
    margin-bottom: unset;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: space-evenly;
  }

  @include media-breakpoint-up(lg) {
    justify-content: space-between;
  }
}
