@import 'import';
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;

  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.title {
  display: flex;
  gap: 0.5ch;
  align-items: center;
}

.sail {
  display: inline;

  @include font($size: rem(14), $line-height: rem(18), $color: $grey-text);

  text-transform: uppercase;
  letter-spacing: 1px;
}

.almostSoldOut {
  @include media-breakpoint-down(sm) {
    margin-bottom: px12;
  }
}

.sailorName {
  margin: 5px 0;

  @include font($size: rem(24), $weight: bold, $line-height: rem(36));

  color: $black;

  @include media-breakpoint-down(lg) {
    @include font($size: rem(20), $line-height: rem(28));
  }

  @include media-breakpoint-down(md) {
    @include font($size: rem(24), $line-height: rem(36));
  }

  @include media-breakpoint-down(sm) {
    @include font($size: rem(20), $line-height: rem(28));
  }
}

.duration {
  margin-bottom: 15px;

  @include font(
    $size: rem(14),
    $weight: 500,
    $line-height: rem(18),
    $color: $red,
    $spacing: 1px,
    $transform: uppercase
  );
}

.accordion {
  @include font($size: rem(15), $line-height: rem(24), $color: $grey-text);
}

.accordionTitle {
  cursor: pointer;

  display: flex;
  align-items: center;

  width: 100%;
  margin-bottom: 4px;
}

.chevron {
  width: 32px;
  height: 32px;
  margin: 0 0 0 4px;

  color: $red;

  transition: all ease-in-out 0.2s;
}

.ports {
  max-width: 85%;
}
