@import 'import';
.overlay {
  position: fixed;
  z-index: $z-index-flyout;
  inset: 0;

  background-color: rgba($black, 0.8);

  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.content {
  position: fixed;
  z-index: $z-index-flyout;

  overflow: visible;
  display: grid;

  background-color: $white;
  box-shadow:
    hsl(206deg 22% 7% / 35%) 0 10px 38px -10px,
    hsl(206deg 22% 7% / 20%) 0 10px 20px -15px;
}

[data-vaul-drawer-direction='right'].content {
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 500px;
}

.content:focus {
  outline: none;
}

.close {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  display: grid;
  place-items: center;

  padding: 10px;

  background-color: transparent;
  border: unset;

  fill: $grey-text;

  svg {
    width: 32px;
    height: 32px;
    padding: 6px;

    background-color: $white-FC;
    border: unset;
    border-radius: 50%;
  }

  &:hover {
    fill: $black;
  }
}

.scrollable {
  position: relative;
  overflow-y: auto;
  height: 100%;
  background-color: $white;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
