@import 'import';
.BoxCheckbox {
  cursor: pointer;

  width: fit-content;
  padding: 4px 16px;

  font-size: 15px;
  line-height: 24px;
  color: $grey-text;

  border: 1px solid #a4a6a9;
  border-radius: 8px;

  &.checked {
    padding: 4px 16px 4px 11px;
    color: $black;
    background-color: $blue-light;
    border: 0;

    .Icon {
      margin-right: 7px;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}
