@import 'import';
.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  > * {
    z-index: 0;
  }
}

.headingSpace {
  display: flex;
  flex-direction: column;
}

.gold {
  .header {
    color: $vv-gold;
  }
}

.backButtonContainer {
  position: relative;

  display: flex;
  align-items: center;
  order: 3;

  width: 90%;
  margin: rem(10) auto rem(20) auto;

  @include media-breakpoint-up(xl) {
    max-width: map-get($grid-breakpoints, 'xl');
    margin: rem(30) auto auto;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  order: -1;

  margin-top: rem(30);

  font-size: rem(20);
  font-weight: bold;
}

.description {
  @include font($size: rem(15), $weight: bold, $align: center);

  width: 70%;
  margin: 7px auto auto;

  @include media-breakpoint-up(md) {
    width: 60%;
    font-size: rem(17);
  }
}

@include media-breakpoint-up(lg) {
  .backButtonContainer {
    order: 1;
    margin: 20px auto auto;
  }

  .header {
    flex: unset;
    order: 2;
    margin-top: -35px;
  }

  .services {
    order: 3;
  }
}
