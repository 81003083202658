@mixin card-box-shadow {
  box-shadow: 0 2px 3px 0 rgb(213 214 215 / 100%);
}

@mixin card-border-radius {
  overflow: hidden;
  border-radius: rem(4px);
}

@mixin package-card-border-radius {
  border: 1px solid $gray-210;
  border-radius: 8px;
}

@mixin disabled-block($opacity: 0.25) {
  pointer-events: none;
  user-select: none;
  opacity: $opacity;
}

@mixin overflow-hidden-max-width {
  overflow-x: hidden;
  max-width: 100vw;
}
