@import 'import';
.DateOfBirth .DateOfBirth__container {
  .TextField {
    margin-bottom: 0;

    + .TextField {
      &::before {
        content: '';

        position: absolute;
        top: 0.3em;
        left: rem(-15);
        transform: scaleX(0.8);

        font-size: rem(24);
        color: $gray-300;
      }
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 992px) {
      margin-left: 5px;
    }
  }

  .TextField__container {
    padding-top: 6px;
  }
}

@include media-breakpoint-up(lg) {
  .DateOfBirth .DateOfBirth__container {
    .TextField {
      select {
        padding-right: 16px;
      }

      .carrot-down {
        right: -2px;
      }
    }
  }
}
