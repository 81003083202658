@import 'import';
.pill {
  @include font($size: rem(13), $weight: 'bold', $line-height: rem(20));

  cursor: pointer;

  display: inline-block;

  min-width: 130px;
  height: 70px;
  padding: 25px;

  text-align: center;
  text-decoration: underline;

  background-color: $white;
  border: 1px solid $gray-010;
  border-radius: 35px;
  outline: none;
  box-shadow: 0 2px 4px 0 $black-008;

  &.selectedPill {
    background-color: #71d6e0;
    border: 1px solid #71d6e0;
    outline: none;
  }
}
