@import 'import';
.AccordionItem {
  &__Container {
    display: flex;
    height: 100%;
    border-bottom: 1px solid $gray-010;

    .DetailsContainer {
      flex: 9;
      padding: rem(16) 0 rem(2);
      text-align: left;

      .Title {
        @include font($size: rem(15), $weight: 'bold', $line-height: 1.67);
      }

      .SubTitle {
        @include font($size: rem(15), $weight: 'normal', $line-height: 1.67);
      }

      .FoldingPanel {
        @include font($size: rem(13), $weight: 'normal', $line-height: 1.54);
      }
    }

    .PlusIcon,
    .MinusIcon {
      cursor: pointer;

      float: right;

      width: rem(30);
      height: rem(30);

      color: #c00;
    }

    .PlusIcon {
      display: flex;
      margin: auto;
    }

    .MinusIcon {
      padding-top: rem(16);
    }
  }
}

.Inactive .FoldingPanel {
  display: none;
  height: 0;
  transition: all 0.4s ease-in-out;
}

.Active .FoldingPanel {
  height: 100%;
  transition: all 0.4s ease-in-out;

  ol,
  ul {
    margin-bottom: 0;
    padding-left: rem(16);

    li {
      text-align: left;
    }
  }
}
