@import '../base/variables';

@mixin focusOutline($bg: 'light') {
  $innerOutlineColor: $black;
  $outerOutlineColor: $white;

  @if ($bg == 'light') {
    $innerOutlineColor: $white;
    $outerOutlineColor: $black;
  }

  outline: 2px $innerOutlineColor solid;
  outline-offset: -2px;
  box-shadow: 0 0 0 2px $outerOutlineColor;
}
